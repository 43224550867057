import React from 'react';

import pixel from '../images/pixel.png';

const Shooting = ({ shootings, openLightbox }) => {
    return (
        <div>
            <h3>Le vintage s’invite aussi à votre mariage !!</h3>
            <p>Louer un de nos véhicules sera un plus lors de votre mariage, Grâce a leurs différents styles Ils colleront parfaitement à votre thème, À la journée ou plus n’hésitez pas à nous contacter pour toute demande</p>
            <div id="shooting-gallery">
                {shootings.map(shooting => {
                    return <div key={shooting.id} onClick={() => openLightbox(shooting.image)} className='shooting-image'>
                        <img src={shooting.image} width="100%" />
                    </div>
                })}
            </div>
        </div>
    )
}

export default Shooting;