import React from 'react';

import pixel from '../images/pixel.png';

const CarList = ({ selectedCar, button = false, cars, chooseCar }) => {

    const classes = [];
    if (button) {
        classes.push('cars-list-buttons');
    }
    classes.push('cars-list');
    /*
    const cars = [
        {
            title: 'Renault 8',
            name: 'renault8',
            info: {
                year: 1967,
                moteur: '1000cc 43cv',
                vitesse: '4 rapports'
            }
        },
        {
            title: 'Renault 4CV',
            name: 'renault4cv',
            info: {
                year: 1958,
                moteur: '800cc 21cv',
                vitesse: '3 rapports'
            }
        },
        {
            title: 'Triumph Spitfire',
            name: 'triumph',
            info: {
                year: 1978,
                moteur: '1500cc 70cv',
                vitesse: '4 rapports'
            }
        },
        {
            title: 'Coccinelle',
            name: 'coccinelle',
            info: {
                year: 1972,
                moteur: '1600cc 50cv',
                vitesse: '4 rapports'
            }
        },
    ];
    */
    return (
        <div className={classes.join(' ')}>
            {cars.map(car => {
                return <div key={car.id} className={`car ${selectedCar && selectedCar.id === car.id ? 'selected' : ''}`} onClick={() => { if (button) { chooseCar(car) } }}>
                    <div className="title">{car.title}</div>
                    <div className="block-car" style={{ backgroundImage: `url(${car.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        <img src={pixel} width="100%" />
                        <div className="info-box">
                            <div className="info">
                                <strong>Année</strong>: {car.year}<br />
                                <strong>Moteur</strong>:  {car.motor}<br />
                                <strong>Boîte de vitesse</strong>:  {car.speed}
                            </div>
                            <button onClick={() => chooseCar(car)} className='button'>Réserver</button>
                        </div> 
                    </div>
                </div>;
            })}
        </div>
    );
};

export default CarList;