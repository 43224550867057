import React from 'react';

import pixel from '../images/pixel.png';

const ToursList = ({ tours }) => {
    return (
        <div className="tours-list">
            {tours.map(tour => {
                return <div key={tour.id} className="tour">
                    <div className="title">{tour.name}</div>
                    <div className="block-tour" style={{ backgroundImage: `url(${tour.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        <img src={pixel} width="100%" />
                        <div className="info-box">
                            <div className="info" dangerouslySetInnerHTML={{ __html: tour.description }} />
                        </div> 
                    </div>
                </div>;
            })}
        </div>
    );
};

export default ToursList;