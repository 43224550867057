import React, { useState } from "react";
import env from "react-dotenv";
import moment from "moment";

const Form = ({ car, tours, date, setShowForm, setFormSended }) => {

    const monthName = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

    const [tour, setTour] = useState('0');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [info, setInfo] = useState('');
    const [day, setDay] = useState(moment(date).format('DD'));
    const [month, setMonth] = useState(moment(date).format('M'));
    const [year, setYear] = useState(moment(date).format('YYYY'));
    const [showError, setShowError] = useState(false);

    const [requiredFiles, setRequiredFiles] = useState({
        tour: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
    });

    const isEmail = (str) => {
        return String(str)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

    const send = async () => {
        let tmpRef = {...requiredFiles};
        if (tour === '0') {
            tmpRef.tour = 'error';
        } else {
            tmpRef.tour = '';
        }
        if (!firstname.trim().length) {
            tmpRef.firstname = 'error';
        } else {
            tmpRef.firstname = '';
        }
        if (!lastname.trim().length) {
            tmpRef.lastname = 'error';
        } else {
            tmpRef.lastname = '';
        }
        if (!email.trim().length || !isEmail(email)) {
            tmpRef.email = 'error';
        } else {
            tmpRef.email = '';
        }
        if (!phone.trim().length || !/\d/.test(phone)) {
            tmpRef.phone = 'error';
        } else {
            tmpRef.phone = '';
        }
        setRequiredFiles(tmpRef);
        // send
        if (![tmpRef.tour, tmpRef.firstname, tmpRef.lastname, tmpRef.email, tmpRef.phone].includes('error')) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ car_id: car.id, tour_id: tour, firstname, lastname, email, phone, booking_date: `${year}-${month}-${day}`, info })
            };
            const response = await fetch(`${env.API_URL}/booking`, requestOptions);
            if (response) {
                const json = await response.json();
                if (json.type === "success") {
                    setTour(0);
                    setFirstname('');
                    setLastname('');
                    setPhone('');
                    setEmail('');
                    setInfo('');
                    setShowForm(false);
                    setFormSended(true);
                } else {
                    setShowError(true);
                }
            }
        }
    }

    return (
        <div id="form">
            <div className="form">
                <div className="title">Réservez votre {car.title} pour le {day} {monthName[month]} {year}</div>
                {showError && <div className="error-message">Erreur</div>}
                <select onChange={(e) => { setTour(e.target.value) }} className={requiredFiles['tour']}>
                    <option value="0">Choisissez votre itinéraire *</option>
                    {tours.map(tour => <option key={tour.id} value={tour.id}>{tour.name}</option>)}
                </select>
                <input onChange={(e) => setFirstname(e.target.value)} className={requiredFiles['firstname']} type="firstname" value={firstname} placeholder="Votre prénom *" />
                <input onChange={(e) => setLastname(e.target.value)} className={requiredFiles['lastname']} type="lastname" value={lastname} placeholder="Votre nom *" />
                <input onChange={(e) => setEmail(e.target.value)} className={requiredFiles['email']} type="email" value={email} placeholder="Votre email *" />
                <input onChange={(e) => setPhone(e.target.value)} className={requiredFiles['phone']} type="phone" value={phone} placeholder="Votre téléphone *" />
                <textarea onChange={(e) => setInfo(e.target.value)} placeholder="Informations complémentaires"></textarea>
                <button onClick={() => send()} className="button button-full">Réserver</button>
                <button onClick={() => setShowForm(false)} className="button button-2 button-full">Annuler</button>
            </div>
        </div>
    )
}

export default Form;