import React from "react";
import moment from 'moment';

import pixel from '../images/pixel.png';

const Day = ({ booked, currentDate, date, onClick }) => {

    let _fullDate = moment(date).format("YYYYMMDD");
    let _date = moment(date).format("DD");
    let _currentDate = moment(currentDate).format("YYYYMMDD");

    const classes = [];
    classes.push("day-block");
    if (_currentDate === _fullDate) {
        classes.push("selected");
    }
    if (_fullDate < _currentDate) {
        classes.push("disabled");
    }
    if (booked) {
        classes.push("booked");
    }

    return (
        <div onClick={() => {
            if (_fullDate >= _currentDate && !booked) {
                onClick(_fullDate);
            }
        }} className={classes.join(" ")}>
            <div className="day-block-content">{_date}</div>
            <img src={pixel} width="100%" />
        </div>
    )
};

export default Day;