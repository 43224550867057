import React, { useState, useEffect } from 'react';
import env from "react-dotenv";
import moment from 'moment';

import previousButton from '../images/previous.png';
import nextButton from '../images/next.png';

import Day from './Day';

const Calendar = ({ car, onClick }) => {

    const monthName = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

    const [year, setYear] = useState(moment().format('YYYY'));
    const [month, setMonth] = useState(moment().format('MM'));
    const [monthIndex, setMonthIndex] = useState(moment().format('M'));
    const [currentDate, setCurrentDate] = useState(moment().format('YYYYMMDD'));
    const [showPreviousButton, setShowPreviousButton] = useState(false);
    const [dates, setDates] = useState([]);
    const [booked, setBooked] = useState([]);

    const changeMonth = (direction) => {
        if (direction === 'next') {
            const nextMonth = moment(`${year}${month}`).add(1, 'month');
            setMonth(nextMonth.format('MM'));
            setMonthIndex(nextMonth.format('M'));
            setYear(nextMonth.format('YYYY'));
            if (nextMonth.format('YYYYMM') > moment().format('YYYYMM')) {
                setShowPreviousButton(true);
            } else {
                setShowPreviousButton(false);
            }
        } else {
            const previousMonth = moment(`${year}${month}`).subtract(1, 'month');
            setMonth(previousMonth.format('MM'));
            setMonthIndex(previousMonth.format('M'));
            setYear(previousMonth.format('YYYY'));
            if (previousMonth.format('YYYYMM') > moment().format('YYYYMM')) {
                setShowPreviousButton(true);
            } else {
                setShowPreviousButton(false);
            }
        }
    };

    const init = async () => {
        const getBooked = await fetch(`${env.API_URL}/booking/${year}/${month}/${car.id}`);
        if (getBooked) {
            const json = await getBooked.json();
            setBooked(json.data);
        }
        const nbDays = moment(`${year}${month}`).daysInMonth();
        const tmpDates = [];
        for (let i = 1; i <= nbDays; i++) {
            tmpDates.push(`${year}${month}${i < 10 ? '0'+i : i}`);
        }
        setDates(tmpDates);
    }

    useEffect(() => {
        init();
    }, [car, month]);

    return (
        <>
            <div className="title">
                {showPreviousButton && <button onClick={() => changeMonth('previous')} className="previous"><img src={previousButton} width="64" /></button>}
                {monthName[monthIndex-1]} {year}
                <button onClick={() => changeMonth('next')} className="next"><img src={nextButton} width="64" /></button>
            </div>
            <div id="calendar">
                {dates.map(date => {
                    return <Day booked={booked.map(b => b.booking_date).includes(moment(date).format('YYYY-MM-DD'))} key={date} currentDate={currentDate} date={date} onClick={onClick} />
                })}
            </div>
        </>
    )
}

export default Calendar;