import React from 'react';

const Footer = () => {
    return (
        <div>
            <footer>
                <div className="content">
                    <div className="footer-block">
                        <h4>&Agrave; propos</h4>
                        <p>
                            <strong>Suspendisse ipsum magna, tincidunt non blandit eget, hendrerit non sapien. Nullam mattis magna et semper varius.</strong><br /><br />
                            Fusce sit amet semper velit, quis aliquam libero. Integer risus sem, aliquet sed ex eu, iaculis ornare massa. Sed quis dui placerat, consequat massa sollicitudin, pellentesque massa. Nullam vestibulum ex ut tortor convallis, ac mollis augue iaculis. Sed ultrices mattis leo at auctor. Mauris quis pulvinar nunc. Vivamus feugiat ac tellus faucibus dignissim. Donec lobortis consequat posuere.
                        </p>
                    </div>
                    <div className="footer-block">
                        <h4>Contact</h4>
                        <ul>
                            <li>
                                <strong>Vintage Vadrouille</strong><br />
                                Chemin Biperrenborda<br />
                                Maison betriene gaina<br />
                                64310 saint pèe sur nivelle
                            </li>
                            <li><a href="mailto:contact@vintagevadrouille.com" target="_blank">contact@vintagevadrouille.com</a></li>
                            <li><a href="tel:0659496327" target="_blank">06 59 49 63 27</a></li>
                        </ul>
                    </div>
                    <div className="footer-block">
                        <h4>Partenaires</h4>
                        <p>
                            ...
                        </p>
                    </div>
                </div>
            </footer>
            <div id="sub-footer">
                Vintage Vadrouille | Visite originale du pays Basque - Réalisé par Realizm-Studio - Mentions Légales - CGV
            </div>
        </div>
    )
}

export default Footer;