import React, { useState, useEffect } from 'react';
import env from "react-dotenv";

import logo from '../images/logo-white-shadow.png';
import video from '../images/video.mp4';
import imageCar from '../images/car.png';
import imageMap from '../images/map.png';
import imageShooting from '../images/shooting.png';
import imageGroups from '../images/groups.png';
import imageBooking from '../images/booking.png';

import Lightbox from '../components/Lightbox';
import CarList from '../components/CarList';
import ToursList from '../components/ToursList';
import Shooting from '../components/Shooting';
import Groups from '../components/Groups';
import Calendar from '../components/Calendar';
import Form from '../components/Form';

const Homepage = ({ scrollRefs, title }) => {

    const [formSended, setFormSended] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [date, setDate] = useState(null);

    /* shootings */
    const [shootings, setShootings] = useState([]);

    const getShootings = async () => {
        const gettedShootings = await fetch(`${env.API_URL}/shootings`);
        if (gettedShootings) {
            const json = await gettedShootings.json();
            setShootings(json.data);
        }
    }
    /* end shootings */

    /* tours */
    const [tours, setTours] = useState([]);

    const getTours = async () => {
        const gettedTours = await fetch(`${env.API_URL}/tours`);
        if (gettedTours) {
            const json = await gettedTours.json();
            setTours(json.data);
        }
    }
    /* end tours */

    /* cars */
    const [cars, setCars] = useState([]);
    const [selectedCar, setSelectedCar] = useState(null);

    const getCars = async () => {
        const gettedCars = await fetch(`${env.API_URL}/cars`);
        if (gettedCars) {
            const json = await gettedCars.json();
            setCars(json.data);
        }
    }

    const chooseCar = (_car) => {
        setSelectedCar(_car);
        setFormSended(false);
        scrollRefs['calendar'].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    /* end cars */

    const onClick = (_date) => {
        setDate(_date);
        setShowForm(true);
    }

    const initPage = async () => {
        await getShootings();
        await getCars();
        await getTours();
    }

    useEffect(() => {
        initPage();
    }, []);

    const [image, setImage] = useState(null);
    const [showImage, setShowImage] = useState(false);

    const openLightbox = (image) => {
        setImage(image);
        setShowImage(true);
    }

    const closeLightbox = () => {
        setImage(null);
        setShowImage(false);
    }

    return (
        <div id="container">
            {showImage && <Lightbox image={image} close={closeLightbox} />}
            {showForm && <Form tours={tours} setFormSended={setFormSended} setShowForm={setShowForm} car={selectedCar} date={date} />}
            <div ref={scrollRefs['home']} className="full-height video-screen">
                <img alt={title} src={logo} width="688" />
                <h1>VISITE DU PAYS BASQUE NATURE, INSOLITE ET GOURMAND</h1>
                <video autoPlay muted loop id="myVideo">
                    <source src={video} type="video/mp4" />
                </video>
            </div>
            <div id="cars" ref={scrollRefs['cars']} className="full-height cars-screen">
                <div className="content">
                    <img alt="Les voitures" src={imageCar} width="64" />
                    <h2>Les voitures</h2>
                    <p>Situé au cœur des montagnes basques, <strong>Vintage Vadrouille</strong> permet de vous évader et de découvrir le <strong>Pays Basque</strong> à bord de <strong>véhicules mythiques</strong>. Une expérience, hors des sentiers battus, qui vous mènera au travers des montagnes et du <strong>littoral basque</strong>.</p>
                    <CarList chooseCar={chooseCar} cars={cars} />
                </div>
            </div>
            <div id="tours" ref={scrollRefs['tours']} className="full-height tours-screen">
                <div className="content">
                    <img alt="Les tours" src={imageMap} width="64" />
                    <h2>Les tours</h2>
                    <ToursList tours={tours} />
                </div>
            </div>
            <div id="shooting" ref={scrollRefs['shooting']} className="full-height shooting-screen">
                <div className="content">
                    <img alt="Shooting" src={imageShooting} width="64" />
                    <h2>Mariages &amp; Shooting</h2>
                    <Shooting openLightbox={openLightbox} shootings={shootings} />
                </div>
            </div>
            {/*
            <div id="groups" ref={scrollRefs['groups']} className="full-height groups-screen">
                <div className="content">
                    <img alt="Groups" src={imageGroups} width="64" />
                    <h2>Groupes &amp; Entreprises</h2>
                    <Groups />
                </div>
            </div>
            */}
            <div id="booking" ref={scrollRefs['calendar']} className="full-height booking-screen">
                <div className="content">
                    <img alt="Booking" src={imageBooking} width="64" />
                    <h2>Réservation</h2>
                    <CarList selectedCar={selectedCar} button={true} chooseCar={chooseCar} cars={cars} />
                    {selectedCar && !formSended && <Calendar car={selectedCar} onClick={onClick} />}
                    {formSended && <div className="success-message">Demande envoyée.<br />Nous allons vous contacter pour finaliser la réservation</div>}
                    {!selectedCar && <p>Veuillez choisir une voiture pour réserver votre tour</p>}
                </div>
            </div>
        </div>
    )
}

export default Homepage;