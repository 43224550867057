import React from "react";

const Lightbox = ({ image, close }) => {
    return (
        <div id="lightbox">
            <button onClick={() => close()}>X</button>
            <img src={image} />
            <div onClick={() => close()} className="background"></div>
        </div>
    )
}

export default Lightbox;