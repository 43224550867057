import React from 'react';
import logo from '../images/logo-white.png';

const Header = ({ scrollRefs, scrollTo }) => {
    return (
        <header className="App-header">
            <img className="logo" src={logo} width="100" />
            <span onClick={() => scrollTo(scrollRefs['home'])} className='link'>Accueil</span>
            <span onClick={() => scrollTo(scrollRefs['cars'])} className='link'>Les voitures</span>
            <span onClick={() => scrollTo(scrollRefs['tours'])} className='link'>Les tours</span>
            <span onClick={() => scrollTo(scrollRefs['shooting'])} className='link'>Mariages &amp; Shootings</span>
            {/* <span onClick={() => scrollTo(scrollRefs['groups'])} className='link'>Groupes &amp; Entreprises</span> */}
            <span onClick={() => scrollTo(scrollRefs['calendar'])} className="button">Réserver</span>
        </header>
    )
}

export default Header;