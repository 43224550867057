import './App.css';
import React, { useState, useEffect, useRef } from 'react';

import Homepage from './pages/Homepage';

import Header from './components/Header';
import Footer from './components/Footer';

function App() {

  const title = "Vintage Vadrouille";

  const [offset, setOffset] = useState(0);

  const scrollRefs = useRef([]);

  const scrollTo = (ref) => {
    console.log({ ref });
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const initPage = () => {
    scrollRefs.current['home'] = React.createRef();
    scrollRefs.current['cars'] = React.createRef();
    scrollRefs.current['tours'] = React.createRef();
    scrollRefs.current['shooting'] = React.createRef();
    scrollRefs.current['groups'] = React.createRef();
    scrollRefs.current['calendar'] = React.createRef();
  }

  useEffect(() => {
    initPage();
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div>
      <Header 
        scrollRefs={scrollRefs.current}
        scrollTo={scrollTo}
      />
      <Homepage 
        scrollRefs={scrollRefs.current}
        title={title}
      />
      <Footer />
    </div>
  )
}

export default App;
